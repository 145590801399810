html {
    overflow-y: scroll;
}

body {
    background-color: white;
}

.state-active {
    background: red!important;
}

.state-hidden {
    display: none!important;
}

@media only screen and (min-width: 768px) {
    div#mainbanner ul.slides li {
        padding-top: 200px;
    }
}

div#mainbanner ul.slides > li[data-slideNO="1"] {
    background-image: url('/extra-images/slide1-background.jpg');
    background-color: white;
}

div#mainbanner ul.slides > li[data-slideNO="2"] {
    background-image: url('/extra-images/slide2-background.jpg');
    background-color: white;
}

div#mainbanner ul.slides > li[data-slideNO="3"] {
    background-image: url('/extra-images/slide3-background.jpg');
    background-color: white;
}

.contact-us-widget li {
    list-style-type: none;
    margin-left: 10px;
}

.kode-bottom-footer-imgDiv img {
    display: block;
    margin: 10px auto;
}

.kode-table tbody tr {
    text-align: center;
}


/*  */

.thbg-color {
    background-color: #ffd452;
}

.kode-table thead th:first-child {
    text-align: center;
}

.kode-pagecontent {
    margin-top: 50px;
}

@media only screen and (min-width: 768px) {
    #modal-register .modal-body {
        height: 600px;
        overflow: scroll;
    }
}


/* small image flash fix */

.glyphicon {
    width: 14px;
    height: 14px;
}


/* front page thumbnails | size fix */

.kode-team-list figure,
.kode-team-list figure img {
    height: 300px;
}


/* navbar for mobiles | color fix  */

@media only screen and (max-width: 768px) {
    .header-8 {
        background-color: #DC143C;
    }
}

div.indexPageContent > section > div.container h3 {
    display: inline-block;
    text-align: center;
}


/* ####################################  */


/* modification to template */


/* end */

aside.kode-pagesidebar h4.panel-title,
aside.kode-pagesidebar div.panel-body {
    text-align: center;
}


/* main banner part fix for loading period */

@media only screen and (min-width: 768px) {
    div#mainbanner > div.flexslider ul.slides {
        height: 700px;
        background-color: #DC143C;
    }
}

.kode-content {
    min-height: 600px;
}


/* marquee settings */

@media only screen and (max-width: 768px) {
    /* don't display on mobiles */
    .marquee,
    .rewardPool {
        display: none;
    }
}


/*
  color theme fix START

*/

.widget_categories ul li {
    border-bottom: 1px solid #E71D36;
}

.kode-team-list figure figcaption h2 a {
    background-color: #011627;
}

.pagination a,
.pagination span， .widget_categories ul li:hover a {
    color: #ffffff;
}

.pagination a:hover,
.pagination span {
    color: #2EC4B6;
}


/*
  color theme fix END

*/


/* slot list page search box style fix START*/

section.kode-pagesection form.search-form input[type="text"] {
    background-color: initial;
    border: initial;
    outline: initial;
    box-shadow: initial;
    border-bottom: solid 1px #E71D36;
    border-radius: 0;
    padding: 0 0 0 27px;
    font-size: 18px;
    color: white;
}

section.kode-pagesection form.search-form .form-control-feedback {
    right: initial;
}


/*
  loading page START
*/

.no-js #loader {
    display: none;
}

.js #loader {
    display: block;
    position: absolute;
    left: 100px;
    top: 0;
}

.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url("/images/logo.png") center no-repeat #ffd452;
}


/*
  loading page END
*/

div.modal-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 2000px;
    background-color: rgba(0, 0, 0, .3);
    z-index: 99;
    display: none;
}

div.modal-background.state-loggingin {
    display: block;
}
